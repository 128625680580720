:root {
  --pure: #f7b500;
  --white: #ffffff;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  background: rgb(238, 238, 238);
}

/* Fixes a bug in the calendar when changing resolution. */
.MuiCalendarOrClockPicker-root {
  display: unset !important;
}
.Mui-selected {
  background-color: var(--pure) !important;
}

[data-testid*="CtaButton"] {
  background-color: var(--pure) !important;
}

[data-testid="member-CtaButton"] {
  background-color: var(--pure) !important;
  text-transform: none;
}

.Mui-disabled[data-testid="member-CtaButton"] {
  background-color: #e5e5e5 !important;
  color: white;
}
